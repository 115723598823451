import React from "react"
import { Layout, Product, SEO } from "../components"
import { graphql } from "gatsby"

export default function accesories({ data }) {
  const products = data.allShopifyCollection.edges[0].node.products
  return (
    <Layout>
      <SEO title="Accesorios" />

      <section className="products products--top-padding">
        {products.length === 0 && <p className="no-products">Por el momento no tenemos productos en esta categoría.</p>}
        {products.length > 0 &&
          products.map(product => (
            <Product
              title={product.title}
              image={product.images[0]}
              url={`/accesorios/${product.handle}`}
              price={product.variants[0].price}
              key={`accesories-${product.id}`}
            />
          ))}
      </section>
    </Layout>
  )
}

export const query = graphql`
  query GET_ACCESORIES {
    allShopifyCollection(filter: { title: { eq: "ACCESORIOS" } }) {
      edges {
        node {
          title
          description
          descriptionHtml
          id
          handle
          products {
            availableForSale
            description
            descriptionHtml
            handle
            id
            images {
              id
              originalSrc
              localFile {
                childImageSharp {
                  fluid(maxWidth: 920) {
                    originalName
                    srcSetWebp
                    tracedSVG
                  }
                }
              }
            }
            variants {
              price
              sku
              title
            }
            title
            tags
          }
          shopifyId
        }
      }
    }
  }
`
